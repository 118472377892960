module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":900,"withWebp":true,"tracedSVG":false,"linkImagesToOriginal":false}},{"resolve":"gatsby-remark-copy-linked-files","options":{"ignoreFileExtensions":[".md"]}},{"resolve":"gatsby-local-autolink-headers","options":{"isIconAfterHeader":true}},{"resolve":"gatsby-remark-vscode","options":{"theme":"Dark+ (default dark)","wrapperClassName":"","injectStyles":true,"extensions":[],"extensionDataDirectory":"/opt/render/project/src/extensions","languageAliases":{},"logLevel":"warn"}}],"remarkPlugins":[null,null],"rehypePlugins":[null,null],"extensions":[".mdx",".md"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"OI Wiki","short_name":"OI Wiki","start_url":"/","display":"standalone","icon":"icon/favicon_512x512.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"4414dc6b1b982a26ab925ea8336e890d"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
